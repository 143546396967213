<template>
  <div id="custom-modal">
    <span class="checkbox">
      <slot name="checkbox"></slot>
    </span>
    <span @click="showModal" class="click-text">
      <slot name="modal-text"></slot>
    </span>
    <CustomModal v-model="state.show">
      <template #modal-title>
        <div class="title">
          <slot name="title-text"></slot>
        </div>
      </template>
      <template #body>
        <slot name="body"></slot>
      </template>
      <template #modal-footer>
        <div class="w-100">
          <button class="custom-btn custom-btn-primary float-right" @click="hideModal(); enableElement(); checkElement();">
            <slot name="button-text"></slot>
          </button>
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import CustomModal from './CustomModal.vue';

export default defineComponent({
  props: { 
    disabledElement: { 
      type: String, 
      required: false, 
    } 
  },
  name: 'modal-template',
  components: {
    CustomModal
  },
  setup(props) {
    const state = reactive({
      show: false,
      variants: [
        'primary'
      ]
    })

    const showModal = () => {
      return state.show = true
    }

    const hideModal = () => {
      return state.show = false
    }

    const enableElement = () => {
      if (props.disabledElement) {
        document.querySelector(props.disabledElement).disabled = false;
      }
    }

    const checkElement = () => {
      if (props.disabledElement) {
        const element = document.querySelector(props.disabledElement); 
        if (!element.checked) { 
          element.click(); 
        }
      }
    }

    onMounted(() => {
      if (props.disabledElement) {
        document.querySelector(props.disabledElement).disabled = true
      }
    });

    return { 
      state,
      showModal,
      hideModal,
      enableElement,
      checkElement
    }
  },
});
</script>

<style>
  .title {
    font-weight: bold;
  }

  .click-text {
    cursor: pointer;
    color: #065699;
  }

  .click-text:hover {
    text-decoration: underline;
  }
</style>