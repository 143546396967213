<template>
  <div v-if="modelValue" class="custom-modal-overlay" @click.self="$emit('update:modelValue', false);">
    <div class="custom-modal-content" @keyup.esc="$emit('update:modelValue', false)" tabindex="0">
      <header class="custom-modal-header">
        <slot name="modal-title"></slot>
        <button class="close" @click="$emit('update:modelValue', false);">X</button>
      </header>
      <main class="custom-modal-body">
        <slot name="body"></slot>
      </main>
      <footer class="custom-modal-footer">
        <slot name="modal-footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomModal',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
